import {Fragment, useRef, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {CheckIcon} from '@heroicons/react/24/outline'

export default function Privacy() {
    const [open, setOpen] = useState(false)

    const cancelButtonRef = useRef(null)



    return (
        <div onClick={() => {setOpen(!open)}}>Privacy Policy
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={() => {}}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div
                            className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel
                                    className="relative transform overflow-hidden rounded-lg bg-black px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                                    <div>
                                        <div className="mt-3 text-left sm:mt-5">
                                            <Dialog.Title as="h3"
                                                          className="text-lg font-medium leading-6 text-brand-light-gray">
                                                Privacy Policy
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <div className="text-sm text-brand-light-gray">
                                                    <div className="elementor-widget-container">
                                                        <br/>
<span className={"text-xl mt-4 pt-4"}>1. An overview of data protection</span>
                                                        <br/>
<span className={"text-lg mt-4 pt-4"}>General information</span> <p>The following information will
                                                        provide you with an easy to navigate overview of what will
                                                        happen with your personal data when you visit this website. The
                                                        term “personal data” comprises all data that can be used to
                                                        personally identify you. For detailed information about the
                                                        subject matter of data protection, please consult our Data
                                                        Protection Declaration, which we have included beneath this
                                                        copy.</p>
                                                        <br/>
<span className={"text-lg mt-4 pt-4"}>Data recording on this website</span> <br/>
<span className={"text-md mt-4 pt-4"}>Who is the
                                                        responsible party for the recording of data on this website
                                                        (i.e., the “controller”)?</span> <p>The data on this website is
                                                        processed by the operator of the website, whose contact
                                                        information is available under section “Information about the
                                                        responsible party (referred to as the “controller” in the GDPR)”
                                                        in this Privacy Policy.</p> <br/>
<span className={"text-md mt-4 pt-4"}>How do we record your data?</span>
                                                        <p>We collect your data as a result of your sharing of your data
                                                            with us. This may, for instance be information you enter
                                                            into our contact form.</p> <p>Other data shall be recorded
                                                        by our IT systems automatically or after you consent to its
                                                        recording during your website visit. This data comprises
                                                        primarily technical information (e.g., web browser, operating
                                                        system, or time the site was accessed). This information is
                                                        recorded automatically when you access this website.</p>
                                                        <br/>
<span className={"text-md mt-4 pt-4"}>What are the purposes we use your data for?</span> <p>A
                                                        portion of the information is generated to guarantee the error
                                                        free provision of the website. Other data may be used to analyze
                                                        your user patterns.</p> <br/>
<span className={"text-md mt-4 pt-4"}>What rights do you have as far as
                                                        your information is concerned?</span> <p>You have the right to
                                                        receive information about the source, recipients, and purposes
                                                        of your archived personal data at any time without having to pay
                                                        a fee for such disclosures. You also have the right to demand
                                                        that your data are rectified or eradicated. If you have
                                                        consented to data processing, you have the option to revoke this
                                                        consent at any time, which shall affect all future data
                                                        processing. Moreover, you have the right to demand that the
                                                        processing of your data be restricted under certain
                                                        circumstances. Furthermore, you have the right to log a
                                                        complaint with the competent supervising agency.</p> <p>Please
                                                        do not hesitate to contact us at any time if you have questions
                                                        about this or any other data protection related issues.</p>
                                                        <br/>
<span className={"text-lg mt-4 pt-4"}>Analysis tools and tools provided by third parties</span>
                                                        <p>There is a possibility that your browsing patterns will be
                                                            statistically analyzed when your visit this website. Such
                                                            analyses are performed primarily with what we refer to as
                                                            analysis programs.</p> <p>For detailed information about
                                                        these analysis programs please consult our Data Protection
                                                        Declaration below.</p>
                                                        <br/>
<span className={"text-xl mt-4 pt-4"}>2. Hosting and Content Delivery Networks (CDN)</span>
                                                        <br/>
<span className={"text-lg mt-4 pt-4"}>Hetzner</span> <p>We host our website with Hetzner. The
                                                        provider is the Hetzner Online GmbH, Industriestr. 25, 91710
                                                        Gunzenhausen, Germany (hereinafter referred to as Hetzner).</p>
                                                        <p>For details, please view the data privacy policy of
                                                            Hetzner: <a
                                                                href="https://www.hetzner.com/de/rechtliches/datenschutz"
                                                                target="_blank"
                                                                rel="noopener noreferrer">https://www.hetzner.com/de/rechtliches/datenschutz</a>.
                                                        </p> <p>We use Hetzner on the basis of Art. 6(1)(f) GDPR. We
                                                        have a legitimate interest in the most reliable depiction of our
                                                        website possible. If appropriate consent has been obtained, the
                                                        processing is carried out exclusively on the basis of Art.
                                                        6(1)(a) GDPR and § 25 (1) TTDSG, insofar the consent includes
                                                        the storage of cookies or the access to information in the
                                                        user’s end device (e.g., device fingerprinting) within the
                                                        meaning of the TTDSG. This consent can be revoked at any
                                                        time.</p>
                                                        <br/>
<span className={"text-md mt-4 pt-4"}>Data processing</span> <p>We have concluded a data processing
                                                        agreement (DPA) with the above-mentioned provider. This is a
                                                        contract mandated by data privacy laws that guarantees that they
                                                        process personal data of our website visitors only based on our
                                                        instructions and in compliance with the GDPR.</p>
                                                        <br/>
<span className={"text-lg mt-4 pt-4"}>Cloudflare</span> <p>We use the “Cloudflare” service provided
                                                        by Cloudflare Inc., 101 Townsend St., San Francisco, CA 94107,
                                                        USA. (hereinafter referred to as “Cloudflare”).</p>
                                                        <p>Cloudflare offers a content delivery network with DNS that is
                                                            available worldwide. As a result, the information transfer
                                                            that occurs between your browser and our website is
                                                            technically routed via Cloudflare’s network. This enables
                                                            Cloudflare to analyze data transactions between your browser
                                                            and our website and to work as a filter between our servers
                                                            and potentially malicious data traffic from the Internet. In
                                                            this context, Cloudflare may also use cookies or other
                                                            technologies deployed to recognize Internet users, which
                                                            shall, however, only be used for the herein described
                                                            purpose.</p> <p>The use of Cloudflare is based on our
                                                        legitimate interest in a provision of our website offerings that
                                                        is as error free and secure as possible (Art. 6(1)(f) GDPR).</p>
                                                        <p>Data transmission to the US is based on the Standard
                                                            Contractual Clauses (SCC) of the European Commission.
                                                            Details can be found here: <a
                                                                href="https://www.cloudflare.com/privacypolicy/"
                                                                target="_blank"
                                                                rel="noopener noreferrer">https://www.cloudflare.com/privacypolicy/</a>.
                                                        </p> <p>For more information on Cloudflare’s security
                                                        precautions and data privacy policies, please follow this
                                                        link: <a href="https://www.cloudflare.com/privacypolicy/"
                                                                 target="_blank"
                                                                 rel="noopener">https://www.cloudflare.com/privacypolicy/</a>.
                                                    </p>
                                                        <br/>
<span className={"text-md mt-4 pt-4"}>Data processing</span> <p>We have concluded a data processing
                                                        agreement (DPA) with the above-mentioned provider. This is a
                                                        contract mandated by data privacy laws that guarantees that they
                                                        process personal data of our website visitors only based on our
                                                        instructions and in compliance with the GDPR.</p>
                                                        <br/>
<span className={"text-lg mt-4 pt-4"}>Amazon CloudFront CDN</span> <p>We use the Content Delivery
                                                        Network Amazon CloudFront CDN. The provider is Amazon Web
                                                        Services EMEA SARL, 38 avenue John F. Kennedy, L-1855,
                                                        Luxembourg (hereinafter referred to as “Amazon”).</p> <p>Amazon
                                                        CloudFront CDN is a globally distributed Content Delivery
                                                        Network. During these transactions, the information transfer
                                                        between your browser and our website is technically routed via
                                                        the Content Delivery Network. This enables us to boost the
                                                        global availability and performance capabilities of our
                                                        website.</p> <p>The use of Amazon CloudFront CDN is based on our
                                                        legitimate interest in keeping the presentation of our web
                                                        services as error free and secure as possible (Art. 6(1)(f)
                                                        GDPR).</p> <p>The data transfer to the United States is based on
                                                        the Standard Contract Clauses of the EU Commission.&nbsp; You
                                                        can find the details here: <a
                                                            href="https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/"
                                                            target="_blank"
                                                            rel="noopener">https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/</a>.
                                                    </p> <p>For more information on Amazon CloudFront CDN please follow
                                                        this link: <a
                                                            href="https://d1.awsstatic.com/legal/privacypolicy/AWS_Privacy_Notice__German_Translation.pdf"
                                                            target="_blank"
                                                            rel="noopener">https://d1.awsstatic.com/legal/privacypolicy/AWS_Privacy_Notice__German_Translation.pdf</a>.
                                                    </p>
                                                        <br/>
<span className={"text-md mt-4 pt-4"}>Data processing</span> <p>We have concluded a data processing
                                                        agreement (DPA) with the above-mentioned provider. This is a
                                                        contract mandated by data privacy laws that guarantees that they
                                                        process personal data of our website visitors only based on our
                                                        instructions and in compliance with the GDPR.</p>
                                                        <br/>
<span className={"text-xl mt-4 pt-4"}>3. General information and mandatory information</span>
                                                        <br/>
<span className={"text-lg mt-4 pt-4"}>Data protection</span> <p>The operators of this website and
                                                        its pages take the protection of your personal data very
                                                        seriously. Hence, we handle your personal data as confidential
                                                        information and in compliance with the statutory data protection
                                                        regulations and this Data Protection Declaration.</p>
                                                        <p>Whenever you use this website, a variety of personal
                                                            information will be collected. Personal data comprises data
                                                            that can be used to personally identify you. This Data
                                                            Protection Declaration explains which data we collect as
                                                            well as the purposes we use this data for. It also explains
                                                            how, and for which purpose the information is collected.</p>
                                                        <p>We herewith advise you that the transmission of data via the
                                                            Internet (i.e., through e-mail communications) may be prone
                                                            to security gaps. It is not possible to completely protect
                                                            data against third-party access.</p>
                                                        <br/>
<span className={"text-lg mt-4 pt-4"}>Information about the responsible party (referred to as the
                                                            “controller” in the GDPR)</span> <p>The data processing
                                                        controller on this website is:</p> <p>Gravity Technologies
                                                        AG<br/>
                                                            Chamerstrasse 172<br/>
                                                            6300 Zug<br/>
                                                            Switzerland<br/></p>

                                                        <p>
                                                            E-mail: info@gty.ag</p>
                                                        <p>The controller is the natural person or legal entity that
                                                            single-handedly or jointly with others makes decisions as to
                                                            the purposes of and resources for the processing of personal
                                                            data (e.g., names, e-mail addresses, etc.).</p>

                                                        <br/>
<span className={"text-lg mt-4 pt-4"}>Storage duration</span> <p>Unless a more specific storage
                                                        period has been specified in this privacy policy, your personal
                                                        data will remain with us until the purpose for which it was
                                                        collected no longer applies. If you assert a justified request
                                                        for deletion or revoke your consent to data processing, your
                                                        data will be deleted, unless we have other legally permissible
                                                        reasons for storing your personal data (e.g., tax or commercial
                                                        law retention periods); in the latter case, the deletion will
                                                        take place after these reasons cease to apply.</p>
                                                        <br/>
<span className={"text-lg mt-4 pt-4"}>General information on the legal basis for the data
                                                            processing on this website</span> <p>If you have consented to
                                                        data processing, we process your personal data on the basis of
                                                        Art. 6(1)(a) GDPR or Art. 9 (2)(a) GDPR, if special categories
                                                        of data are processed according to Art. 9 (1) DSGVO. In the case
                                                        of explicit consent to the transfer of personal data to third
                                                        countries, the data processing is also based on Art. 49 (1)(a)
                                                        GDPR. If you have consented to the storage of cookies or to the
                                                        access to information in your end device (e.g., via device
                                                        fingerprinting), the data processing is additionally based on §
                                                        25 (1) TTDSG. The consent can be revoked at any time. If your
                                                        data is required for the fulfillment of a contract or for the
                                                        implementation of pre-contractual measures, we process your data
                                                        on the basis of Art. 6(1)(b) GDPR. Furthermore, if your data is
                                                        required for the fulfillment of a legal obligation, we process
                                                        it on the basis of Art. 6(1)(c) GDPR. Furthermore, the data
                                                        processing may be carried out on the basis of our legitimate
                                                        interest according to Art. 6(1)(f) GDPR. Information on the
                                                        relevant legal basis in each individual case is provided in the
                                                        following paragraphs of this privacy policy.</p>
                                                        <br/>
<span className={"text-lg mt-4 pt-4"}>Information on data transfer to the USA and other non-EU
                                                            countries</span> <p>Among other things, we use tools of
                                                        companies domiciled in the United States or other from a data
                                                        protection perspective non-secure non-EU countries. If these
                                                        tools are active, your personal data may potentially be
                                                        transferred to these non-EU countries and may be processed
                                                        there. We must point out that in these countries, a data
                                                        protection level that is comparable to that in the EU cannot be
                                                        guaranteed. For instance, U.S. enterprises are under a mandate
                                                        to release personal data to the security agencies and you as the
                                                        data subject do not have any litigation options to defend
                                                        yourself in court. Hence, it cannot be ruled out that U.S.
                                                        agencies (e.g., the Secret Service) may process, analyze, and
                                                        permanently archive your personal data for surveillance
                                                        purposes. We have no control over these processing
                                                        activities.</p><br/>
<span className={"text-lg mt-4 pt-4"}>Revocation of your consent to the processing
                                                        of data</span> <p>A wide range of data processing transactions are
                                                        possible only subject to your express consent. You can also
                                                        revoke at any time any consent you have already given us. This
                                                        shall be without prejudice to the lawfulness of any data
                                                        collection that occurred prior to your revocation.</p>
                                                        <br/>
<span className={"text-lg mt-4 pt-4"}>Right to object to the collection of data in special cases;
                                                            right to object to direct advertising (Art. 21 GDPR)</span>
                                                        <p>IN THE EVENT THAT DATA ARE PROCESSED ON THE BASIS OF ART.
                                                            6(1)(E) OR (F) GDPR, YOU HAVE THE RIGHT TO AT ANY TIME
                                                            OBJECT TO THE PROCESSING OF YOUR PERSONAL DATA BASED ON
                                                            GROUNDS ARISING FROM YOUR UNIQUE SITUATION. THIS ALSO
                                                            APPLIES TO ANY PROFILING BASED ON THESE PROVISIONS. TO
                                                            DETERMINE THE LEGAL BASIS, ON WHICH ANY PROCESSING OF DATA
                                                            IS BASED, PLEASE CONSULT THIS DATA PROTECTION DECLARATION.
                                                            IF YOU LOG AN OBJECTION, WE WILL NO LONGER PROCESS YOUR
                                                            AFFECTED PERSONAL DATA, UNLESS WE ARE IN A POSITION TO
                                                            PRESENT COMPELLING PROTECTION WORTHY GROUNDS FOR THE
                                                            PROCESSING OF YOUR DATA, THAT OUTWEIGH YOUR INTERESTS,
                                                            RIGHTS AND FREEDOMS OR IF THE PURPOSE OF THE PROCESSING IS
                                                            THE CLAIMING, EXERCISING OR DEFENCE OF LEGAL ENTITLEMENTS
                                                            (OBJECTION PURSUANT TO ART. 21(1) GDPR).</p> <p>IF YOUR
                                                        PERSONAL DATA IS BEING PROCESSED IN ORDER TO ENGAGE IN DIRECT
                                                        ADVERTISING, YOU HAVE THE RIGHT TO OBJECT TO THE PROCESSING OF
                                                        YOUR AFFECTED PERSONAL DATA FOR THE PURPOSES OF SUCH ADVERTISING
                                                        AT ANY TIME. THIS ALSO APPLIES TO PROFILING TO THE EXTENT THAT
                                                        IT IS AFFILIATED WITH SUCH DIRECT ADVERTISING. IF YOU OBJECT,
                                                        YOUR PERSONAL DATA WILL SUBSEQUENTLY NO LONGER BE USED FOR
                                                        DIRECT ADVERTISING PURPOSES (OBJECTION PURSUANT TO ART. 21(2)
                                                        GDPR).</p>
                                                        <br/>
<span className={"text-lg mt-4 pt-4"}>Right to log a complaint with the competent supervisory
                                                            agency</span> <p>In the event of violations of the GDPR, data
                                                        subjects are entitled to log a complaint with a supervisory
                                                        agency, in particular in the member state where they usually
                                                        maintain their domicile, place of work or at the place where the
                                                        alleged violation occurred. The right to log a complaint is in
                                                        effect regardless of any other administrative or court
                                                        proceedings available as legal recourses.</p>
                                                        <br/>
<span className={"text-lg mt-4 pt-4"}>Right to data portability</span> <p>You have the right to
                                                        demand that we hand over any data we automatically process on
                                                        the basis of your consent or in order to fulfil a contract be
                                                        handed over to you or a third party in a commonly used, machine
                                                        readable format. If you should demand the direct transfer of the
                                                        data to another controller, this will be done only if it is
                                                        technically feasible.</p>
                                                        <br/>
<span className={"text-lg mt-4 pt-4"}>SSL and/or TLS encryption</span> <p>For security reasons and
                                                        to protect the transmission of confidential content, such as
                                                        purchase orders or inquiries you submit to us as the website
                                                        operator, this website uses either an SSL or a TLS encryption
                                                        program. You can recognize an encrypted connection by checking
                                                        whether the address line of the browser switches from “http://”
                                                        to “https://” and also by the appearance of the lock icon in the
                                                        browser line.</p> <p>If the SSL or TLS encryption is activated,
                                                        data you transmit to us cannot be read by third parties.</p>
                                                        <br/>
<span className={"text-lg mt-4 pt-4"}>Encrypted payment transactions on this website</span> <p>If
                                                        you are under an obligation to share your payment information
                                                        (e.g. account number if you give us the authority to debit your
                                                        bank account) with us after you have entered into a fee-based
                                                        contract with us, this information is required to process
                                                        payments.</p> <p>Payment transactions using common modes of
                                                        paying (Visa/MasterCard, debit to your bank account) are
                                                        processed exclusively via encrypted SSL or TLS connections. You
                                                        can recognize an encrypted connection by checking whether the
                                                        address line of the browser switches from “http://” to
                                                        “https://” and also by the appearance of the lock icon in the
                                                        browser line.</p> <p>If the communication with us is encrypted,
                                                        third parties will not be able to read the payment information
                                                        you share with us.</p>
                                                        <br/>
<span className={"text-lg mt-4 pt-4"}>Information about, rectification and eradication of
                                                            data</span> <p>Within the scope of the applicable statutory
                                                        provisions, you have the right to at any time demand information
                                                        about your archived personal data, their source and recipients
                                                        as well as the purpose of the processing of your data. You may
                                                        also have a right to have your data rectified or eradicated. If
                                                        you have questions about this subject matter or any other
                                                        questions about personal data, please do not hesitate to contact
                                                        us at any time.</p>
                                                        <br/>
<span className={"text-lg mt-4 pt-4"}>Right to demand processing restrictions</span> <p>You have the
                                                        right to demand the imposition of restrictions as far as the
                                                        processing of your personal data is concerned. To do so, you may
                                                        contact us at any time. The right to demand restriction of
                                                        processing applies in the following cases:</p>
                                                        <ul>
                                                            <li>In the event that you should dispute the correctness of
                                                                your data archived by us, we will usually need some time
                                                                to verify this claim. During the time that this
                                                                investigation is ongoing, you have the right to demand
                                                                that we restrict the processing of your personal data.
                                                            </li>
                                                            <li>If the processing of your personal data was/is conducted
                                                                in an unlawful manner, you have the option to demand the
                                                                restriction of the processing of your data in lieu of
                                                                demanding the eradication of this data.
                                                            </li>
                                                            <li>If we do not need your personal data any longer and you
                                                                need it to exercise, defend or claim legal entitlements,
                                                                you have the right to demand the restriction of the
                                                                processing of your personal data instead of its
                                                                eradication.
                                                            </li>
                                                            <li>If you have raised an objection pursuant to Art. 21(1)
                                                                GDPR, your rights and our rights will have to be weighed
                                                                against each other. As long as it has not been
                                                                determined whose interests prevail, you have the right
                                                                to demand a restriction of the processing of your
                                                                personal data.
                                                            </li>
                                                        </ul>
                                                        <p>If you have restricted the processing of your personal data,
                                                            these data – with the exception of their archiving – may be
                                                            processed only subject to your consent or to claim, exercise
                                                            or defend legal entitlements or to protect the rights of
                                                            other natural persons or legal entities or for important
                                                            public interest reasons cited by the European Union or a
                                                            member state of the EU.</p>
                                                        <br/>
<span className={"text-lg mt-4 pt-4"}>Rejection of unsolicited e-mails</span> <p>We herewith object
                                                        to the use of contact information published in conjunction with
                                                        the mandatory information to be provided in our Site Notice to
                                                        send us promotional and information material that we have not
                                                        expressly requested. The operators of this website and its pages
                                                        reserve the express right to take legal action in the event of
                                                        the unsolicited sending of promotional information, for instance
                                                        via SPAM messages.</p>
                                                        <br/>
<span className={"text-xl mt-4 pt-4"}>4. Recording of data on this website</span>
                                                        <br/>
<span className={"text-lg mt-4 pt-4"}>Cookies</span> <p>Our websites and pages use what the industry
                                                        refers to as “cookies.” Cookies are small text files that do not
                                                        cause any damage to your device. They are either stored
                                                        temporarily for the duration of a session (session cookies) or
                                                        they are permanently archived on your device (permanent
                                                        cookies). Session cookies are automatically deleted once you
                                                        terminate your visit. Permanent cookies remain archived on your
                                                        device until you actively delete them, or they are automatically
                                                        eradicated by your web browser.</p> <p>In some cases, it is
                                                        possible that third-party cookies are stored on your device once
                                                        you enter our site (third-party cookies). These cookies enable
                                                        you or us to take advantage of certain services offered by the
                                                        third party (e.g., cookies for the processing of payment
                                                        services).</p> <p>Cookies have a variety of functions. Many
                                                        cookies are technically essential since certain website
                                                        functions would not work in the absence of the cookies (e.g.,
                                                        the shopping cart function or the display of videos). The
                                                        purpose of other cookies may be the analysis of user patterns or
                                                        the display of promotional messages.</p> <p>Cookies, which are
                                                        required for the performance of electronic communication
                                                        transactions, or for the provision of certain functions you want
                                                        to use (e.g., for the shopping cart function) or those that are
                                                        necessary for the optimization (required cookies) of the website
                                                        (e.g., cookies that provide measurable insights into the web
                                                        audience), shall be stored on the basis of Art. 6(1)(f) GDPR,
                                                        unless a different legal basis is cited. The operator of the
                                                        website has a legitimate interest in the storage of required
                                                        cookies to ensure the technically error free and optimized
                                                        provision of the operator’s services. If your consent to the
                                                        storage of the cookies and similar recognition technologies has
                                                        been requested, processing occurs exclusively on the basis of
                                                        the consent obtained (Art. 6(1)(a) GDPR and § 25 (1) TTDSG);
                                                        this consent may be revoked at any time.</p> <p>You have the
                                                        option to set up your browser in such a manner that you will be
                                                        notified any time cookies are placed and to permit the
                                                        acceptance of cookies only in specific cases. You may also
                                                        exclude the acceptance of cookies in certain cases or in general
                                                        or activate the delete function for the automatic eradication of
                                                        cookies when the browser closes. If cookies are deactivated, the
                                                        functions of this website may be limited.</p> <p>In the event
                                                        that third-party cookies are used or if cookies are used for
                                                        analytical purposes, we will separately notify you in
                                                        conjunction with this Data Protection Policy and, if applicable,
                                                        ask for your consent.</p>
                                                        <br/>
<span className={"text-lg mt-4 pt-4"}>GDPR Legal Cookie by Shopify</span> <p>Our website uses GDPR
                                                        Legal Cookie by Shopify to obtain your consent for the storage
                                                        of certain cookies on your device or for the use of certain
                                                        technologies and to document your consent in a data protection
                                                        law compliant manner. The provider of this technology is Shopify
                                                        International Limited, Victoria Buildings, 1-2 Haddington Road,
                                                        Dublin 4, D04 XN32, Ireland (hereinafter referred to as
                                                        “Shopify”).</p> <p>Whenever you access our website, a connection
                                                        to Shopify’s servers will be established to obtain your consent
                                                        as well as other declarations related to the use of cookies.
                                                        Subsequently, Shopify stores a cookie in your browser in order
                                                        to be able to allocate the granted consent or its revocation.
                                                        The thus recorded data will be stored until you ask us to delete
                                                        them, delete the Shopify cookie yourself or if the purpose of
                                                        storing the data has ended. This does not affect mandatory
                                                        statutory retention periods. For more details please review: <a
                                                            href="https://apps.shopify.com/gdpr-legal-cookie"
                                                            target="_blank"
                                                            rel="noopener noreferrer">https://apps.shopify.com/gdpr-legal-cookie</a>.
                                                    </p> <p>We use GDPR Legal Cookie by Shopify to obtain the consent
                                                        for the use of cookies that are mandated by law. The legal basis
                                                        for this is Art. 6(1)(c) GDPR.</p>
                                                        <br/>
<span className={"text-md mt-4 pt-4"}>Data processing</span> <p>We have concluded a data processing
                                                        agreement (DPA) with the above-mentioned provider. This is a
                                                        contract mandated by data privacy laws that guarantees that they
                                                        process personal data of our website visitors only based on our
                                                        instructions and in compliance with the GDPR.</p>
                                                        <br/>
<span className={"text-lg mt-4 pt-4"}>Server log files</span> <p>The provider of this website and
                                                        its pages automatically collects and stores information in
                                                        so-called server log files, which your browser communicates to
                                                        us automatically. The information comprises:</p>
                                                        <ul>
                                                            <li>The type and version of browser used</li>
                                                            <li>The used operating system</li>
                                                            <li>Referrer URL</li>
                                                            <li>The hostname of the accessing computer</li>
                                                            <li>The time of the server inquiry</li>
                                                            <li>The IP address</li>
                                                        </ul>
                                                        <p>This data is not merged with other data sources.</p> <p>This
                                                        data is recorded on the basis of Art. 6(1)(f) GDPR. The operator
                                                        of the website has a legitimate interest in the technically
                                                        error free depiction and the optimization of the operator’s
                                                        website. In order to achieve this, server log files must be
                                                        recorded.</p>
                                                        <br/>
<span className={"text-lg mt-4 pt-4"}>Contact form</span> <p>If you submit inquiries to us via our
                                                        contact form, the information provided in the contact form as
                                                        well as any contact information provided therein will be stored
                                                        by us in order to handle your inquiry and in the event that we
                                                        have further questions. We will not share this information
                                                        without your consent.</p> <p>The processing of these data is
                                                        based on Art. 6(1)(b) GDPR, if your request is related to the
                                                        execution of a contract or if it is necessary to carry out
                                                        pre-contractual measures. In all other cases the processing is
                                                        based on our legitimate interest in the effective processing of
                                                        the requests addressed to us (Art. 6(1)(f) GDPR) or on your
                                                        agreement (Art. 6(1)(a) GDPR) if this has been requested; the
                                                        consent can be revoked at any time.</p> <p>The information you
                                                        have entered into the contact form shall remain with us until
                                                        you ask us to eradicate the data, revoke your consent to the
                                                        archiving of data or if the purpose for which the information is
                                                        being archived no longer exists (e.g., after we have concluded
                                                        our response to your inquiry). This shall be without prejudice
                                                        to any mandatory legal provisions, in particular retention
                                                        periods.</p>
                                                        <br/>
<span className={"text-lg mt-4 pt-4"}>Request by e-mail, telephone, or fax</span> <p>If you contact
                                                        us by e-mail, telephone or fax, your request, including all
                                                        resulting personal data (name, request) will be stored and
                                                        processed by us for the purpose of processing your request. We
                                                        do not pass these data on without your consent.</p> <p>These
                                                        data are processed on the basis of Art. 6(1)(b) GDPR if your
                                                        inquiry is related to the fulfillment of a contract or is
                                                        required for the performance of pre-contractual measures. In all
                                                        other cases, the data are processed on the basis of our
                                                        legitimate interest in the effective handling of inquiries
                                                        submitted to us (Art. 6(1)(f) GDPR) or on the basis of your
                                                        consent (Art. 6(1)(a) GDPR) if it has been obtained; the consent
                                                        can be revoked at any time.</p> <p>The data sent by you to us
                                                        via contact requests remain with us until you request us to
                                                        delete, revoke your consent to the storage or the purpose for
                                                        the data storage lapses (e.g. after completion of your request).
                                                        Mandatory statutory provisions - in particular statutory
                                                        retention periods - remain unaffected.</p>
                                                        <br/>
<span className={"text-lg mt-4 pt-4"}>Registration on this website</span> <p>You have the option to
                                                        register on this website to be able to use additional website
                                                        functions. We shall use the data you enter only for the purpose
                                                        of using the respective offer or service you have registered
                                                        for. The required information we request at the time of
                                                        registration must be entered in full. Otherwise, we shall reject
                                                        the registration.</p> <p>To notify you of any important changes
                                                        to the scope of our portfolio or in the event of technical
                                                        modifications, we shall use the e-mail address provided during
                                                        the registration process.</p> <p>We shall process the data
                                                        entered during the registration process on the basis of your
                                                        consent (Art. 6(1)(a) GDPR).</p> <p>The data recorded during the
                                                        registration process shall be stored by us as long as you are
                                                        registered on this website. Subsequently, such data shall be
                                                        deleted. This shall be without prejudice to mandatory statutory
                                                        retention obligations.</p>
                                                        <br/>
<span className={"text-xl mt-4 pt-4"}>5. Analysis tools and advertising</span>
                                                        <br/>
<span className={"text-lg mt-4 pt-4"}>Google Tag Manager</span> <p>We use the Google Tag Manager.
                                                        The provider is Google Ireland Limited, Gordon House, Barrow
                                                        Street, Dublin 4, Ireland</p> <p>The Google Tag Manager is a
                                                        tool that allows us to integrate tracking or statistical tools
                                                        and other technologies on our website. The Google Tag Manager
                                                        itself does not create any user profiles, does not store
                                                        cookies, and does not carry out any independent analyses. It
                                                        only manages and runs the tools integrated via it. However, the
                                                        Google Tag Manager does collect your IP address, which may also
                                                        be transferred to Google’s parent company in the United
                                                        States.</p> <p>The Google Tag Manager is used on the basis of
                                                        Art. 6(1)(f) GDPR. The website operator has a legitimate
                                                        interest in the quick and uncomplicated integration and
                                                        administration of various tools on his website. If appropriate
                                                        consent has been obtained, the processing is carried out
                                                        exclusively on the basis of Art. 6(1)(a) GDPR and § 25 (1)
                                                        TTDSG, insofar the consent includes the storage of cookies or
                                                        the access to information in the user’s end device (e.g., device
                                                        fingerprinting) within the meaning of the TTDSG. This consent
                                                        can be revoked at any time.</p>
                                                        <br/>
<span className={"text-lg mt-4 pt-4"}>Google Analytics</span> <p>This website uses functions of the
                                                        web analysis service Google Analytics. The provider of this
                                                        service is Google Ireland Limited (“Google”), Gordon House,
                                                        Barrow Street, Dublin 4, Ireland.</p> <p>Google Analytics
                                                        enables the website operator to analyze the behavior patterns of
                                                        website visitors. To that end, the website operator receives a
                                                        variety of user data, such as pages accessed, time spent on the
                                                        page, the utilized operating system and the user’s origin. This
                                                        data is summarized in a user-ID and assigned to the respective
                                                        end device of the website visitor.</p> <p>Furthermore, Google
                                                        Analytics allows us to record your mouse and scroll movements
                                                        and clicks, among other things. Google Analytics uses various
                                                        modeling approaches to augment the collected data sets and uses
                                                        machine learning technologies in data analysis.</p>
                                                        <p>Google Analytics uses technologies that make the recognition
                                                            of the user for the purpose of analyzing the user behavior
                                                            patterns (e.g., cookies or device fingerprinting). The
                                                            website use information recorded by Google is, as a rule
                                                            transferred to a Google server in the United States, where
                                                            it is stored.</p> <p>This analysis tool is used on the basis
                                                        of Art. 6(1)(f) GDPR. The operator of this website has a
                                                        legitimate interest in the analysis of user patterns to optimize
                                                        both, the services offered online and the operator’s advertising
                                                        activities. If appropriate consent has been obtained, the
                                                        processing is carried out exclusively on the basis of Art.
                                                        6(1)(a) GDPR and § 25 (1) TTDSG, insofar the consent includes
                                                        the storage of cookies or the access to information in the
                                                        user’s end device (e.g., device fingerprinting) within the
                                                        meaning of the TTDSG. This consent can be revoked at any
                                                        time.</p> <p>Data transmission to the US is based on the
                                                        Standard Contractual Clauses (SCC) of the European Commission.
                                                        Details can be found here: <a
                                                            href="https://privacy.google.com/businesses/controllerterms/mccs/"
                                                            target="_blank"
                                                            rel="noopener noreferrer">https://privacy.google.com/businesses/controllerterms/mccs/</a>.
                                                    </p>  <br/>
<span className={"text-md mt-4 pt-4"}>Browser plug-in</span> <p>You can prevent the recording and
                                                        processing of your data by Google by downloading and installing
                                                        the browser plugin available under the following link: <a
                                                            href="https://tools.google.com/dlpage/gaoptout?hl=en"
                                                            target="_blank"
                                                            rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout?hl=en</a>.
                                                    </p> <p>For more information about the handling of user data by
                                                        Google Analytics, please consult Google’s Data Privacy
                                                        Declaration at: <a
                                                            href="https://support.google.com/analytics/answer/6004245?hl=en"
                                                            target="_blank"
                                                            rel="noopener noreferrer">https://support.google.com/analytics/answer/6004245?hl=en</a>.
                                                    </p><br/>
<span className={"text-md mt-4 pt-4"}>Google Signals</span> <p>We use Google Signals. Whenever you
                                                        visit our website, Google Analytics records, among other things,
                                                        your location, the progression of your search and YouTube
                                                        progression as well as demographic data (site visitor data).
                                                        This data may be used for customized advertising with the
                                                        assistance of Google Signal. If you have a Google account, your
                                                        site visitor information will be linked to your Google account
                                                        by Google Signal and used to send you customized promotional
                                                        messages. The data is also used to compile anonymized statistics
                                                        of our users’ online patterns.</p>
                                                        <br/>
<span className={"text-md mt-4 pt-4"}>Contract data processing</span> <p>We have executed a contract
                                                        data processing agreement with Google and are implementing the
                                                        stringent provisions of the German data protection agencies to
                                                        the fullest when using Google Analytics.</p>
                                                        <br/>
<span className={"text-md mt-4 pt-4"}>Google Analytics E-Commerce-Tracking</span> <p>This website
                                                        uses the “E-Commerce Tracking” function of Google Analytics.
                                                        With the assistance of E-Commerce Tracking, the website operator
                                                        is in a position to analyze the purchasing patterns of website
                                                        visitors with the aim of improving the operator’s online
                                                        marketing campaigns. In this context, information, such as the
                                                        orders placed, the average order values, shipping costs and the
                                                        time from viewing the product to making the purchasing decision
                                                        are tracked. These data may be consolidated by Google under a
                                                        transaction ID, which is allocated to the respective user or the
                                                        user’s device.</p>
                                                        <br/>
<span className={"text-lg mt-4 pt-4"}>Hotjar</span> <p>This website utilizes Hotjar. The provider is
                                                        Hotjar Ltd., Level 2, St Julians Business Centre, 3, Elia Zammit
                                                        Street, St Julians STJ 1000, Malta, Europe (website: <a
                                                            href="https://www.hotjar.com" target="_blank"
                                                            rel="noopener noreferrer">https://www.hotjar.com</a>).</p>
                                                        <p>Hotjar is a tool used to analyze your user patterns on this
                                                            website. Hotjar allows us to for instance record your mouse
                                                            and scroll movements as well as your click. During this
                                                            process, Hotjar also has the capability to determine how
                                                            long your cursor remained in a certain position. Based on
                                                            this information, Hotjar compiles so-called Heatmaps, that
                                                            make possible to determine which parts of the website the
                                                            website visitor reviews with preference.</p> <p>We are also
                                                        able to determine how long you have stayed on a page of this
                                                        website and when you left. We can also determine at which point
                                                        you suspended making entries into a contact form (so-called
                                                        conversion funnels).</p> <p>Furthermore, Hotjar can be deployed
                                                        to obtain direct feedback from website visitors. This function
                                                        aims at the improvement of the website offerings of the website
                                                        operator.</p> <p>Hotjar uses technologies that make it possible
                                                        to recognize the user for the purpose of analyzing the user
                                                        patterns (e.g., cookies or the deployment of device
                                                        fingerprinting).</p> <p>The use of this analysis tool is based
                                                        on Art. 6(1)(f) GDPR. The website operator has a legitimate
                                                        interest in the analysis of user patterns, in order to optimize
                                                        the operator’s web offerings and advertising. If appropriate
                                                        consent has been obtained, the processing is carried out
                                                        exclusively on the basis of Art. 6(1)(a) GDPR and § 25 (1)
                                                        TTDSG, insofar the consent includes the storage of cookies or
                                                        the access to information in the user’s end device (e.g., device
                                                        fingerprinting) within the meaning of the TTDSG. This consent
                                                        can be revoked at any time.</p> <br/>
<span className={"text-md mt-4 pt-4"}>Deactivation of Hotjar</span>
                                                        <p>If you would like to deactivate the recording of data by
                                                            Hotjar, please click on the link below and follow the
                                                            instructions provided under the link: <a
                                                                href="https://www.hotjar.com/policies/do-not-track/"
                                                                target="_blank"
                                                                rel="noopener noreferrer">https://www.hotjar.com/policies/do-not-track/</a>.
                                                        </p> <p>Please keep in mind that you will have to separately
                                                        deactivate Hotjar for every browser and every device.</p> <p>For
                                                        more detailed information about Hotjar and the data to be
                                                        recorded, please consult the Data Privacy Declaration of Hotjar
                                                        under the following link: <a
                                                            href="https://www.hotjar.com/privacy" target="_blank"
                                                            rel="noopener noreferrer">https://www.hotjar.com/privacy</a>.
                                                    </p>
                                                        <br/>
<span className={"text-md mt-4 pt-4"}>Data processing</span> <p>We have concluded a data processing
                                                        agreement (DPA) with the above-mentioned provider. This is a
                                                        contract mandated by data privacy laws that guarantees that they
                                                        process personal data of our website visitors only based on our
                                                        instructions and in compliance with the GDPR.</p>
                                                        <br/>
<span className={"text-lg mt-4 pt-4"}>Google Ads</span> <p>The website operator uses Google Ads.
                                                        Google Ads is an online promotional program of Google Ireland
                                                        Limited (“Google”), Gordon House, Barrow Street, Dublin 4,
                                                        Ireland.</p> <p>Google Ads enables us to display ads in the
                                                        Google search engine or on third-party websites, if the user
                                                        enters certain search terms into Google (keyword targeting). It
                                                        is also possible to place targeted ads based on the user data
                                                        Google has in its possession (e.g., location data and interests;
                                                        target group targeting). As the website operator, we can analyze
                                                        these data quantitatively, for instance by analyzing which
                                                        search terms resulted in the display of our ads and how many ads
                                                        led to respective clicks.</p> <p>The use of Google Ads is based
                                                        on Art. 6(1)(f) GDPR. The website operator has a legitimate
                                                        interest in marketing the operator’s services and products as
                                                        effectively as possible.</p> <p>Data transmission to the US is
                                                        based on the Standard Contractual Clauses (SCC) of the European
                                                        Commission. Details can be found here: <a
                                                            href="https://policies.google.com/privacy/frameworks"
                                                            target="_blank"
                                                            rel="noopener noreferrer">https://policies.google.com/privacy/frameworks</a> and <a
                                                            href="https://privacy.google.com/businesses/controllerterms/mccs/"
                                                            target="_blank"
                                                            rel="noopener noreferrer">https://privacy.google.com/businesses/controllerterms/mccs/</a>.
                                                    </p>
                                                        <br/>
<span className={"text-lg mt-4 pt-4"}>Google AdSense (not personalized)</span> <p>This website uses
                                                        Google AdSense, an ad embedding service provided by Google
                                                        Ireland Limited (“Google”), Gordon House, Barrow Street, Dublin
                                                        4, Ireland.</p> <p>We use Google AdSense in the
                                                        “non-personalized” mode. Contrary to the personalized mode, the
                                                        ads are not based on your previous user patterns and the service
                                                        does not generate a user profile for you. Instead, the service
                                                        uses so-called “context information” to choose the ads that are
                                                        posted for you to view. The selected ads are thus based e.g., on
                                                        your location, the content of the website you are visiting at
                                                        the time, or the search terms you are using. To learn more about
                                                        the distinct differences between personalized targeting and
                                                        targeting that has not been personalized by Google AdSense,
                                                        please click on the following link: <a
                                                            href="https://support.google.com/adsense/answer/9007336"
                                                            target="_blank"
                                                            rel="noopener noreferrer">https://support.google.com/adsense/answer/9007336</a>.
                                                    </p> <p>Please keep in mind that if Google Adsense is used in the
                                                        non-personalized mode, it is possible that cookies are stored or
                                                        comparable recognition technologies (e.g., device
                                                        fingerprinting) are used.</p> <p>AdSense is used on the basis of
                                                        Art. 6(1)(f) GDPR. The website operator has a legitimate
                                                        interest in making the marketing of the website as effective as
                                                        possible. If appropriate consent has been obtained, the
                                                        processing is carried out exclusively on the basis of Art.
                                                        6(1)(a) GDPR and § 25 (1) TTDSG, insofar the consent includes
                                                        the storage of cookies or the access to information in the
                                                        user’s end device (e.g., device fingerprinting) within the
                                                        meaning of the TTDSG. This consent can be revoked at any
                                                        time.</p> <p>Data transmission to the US is based on the
                                                        Standard Contractual Clauses (SCC) of the European Commission.
                                                        Details can be found here: <a
                                                            href="https://privacy.google.com/businesses/controllerterms/mccs/"
                                                            target="_blank"
                                                            rel="noopener noreferrer">https://privacy.google.com/businesses/controllerterms/mccs/</a>.
                                                    </p> <p>You have the option to autonomously adjust the advertising
                                                        settings in your user account. To do so, please click on the
                                                        link provided below and log in: <a
                                                            href="https://adssettings.google.com/authenticated"
                                                            target="_blank"
                                                            rel="noopener noreferrer">https://adssettings.google.com/authenticated</a>.
                                                    </p> <p>For more information about Google’s advertising
                                                        technologies, please click here: <a
                                                            href="https://policies.google.com/technologies/ads"
                                                            target="_blank"
                                                            rel="noopener noreferrer">https://policies.google.com/technologies/ads</a> and <a
                                                            href="https://www.google.de/intl/de/policies/privacy/"
                                                            target="_blank"
                                                            rel="noopener noreferrer">https://www.google.de/intl/de/policies/privacy/</a>.
                                                    </p>
                                                        <br/>
<span className={"text-lg mt-4 pt-4"}>Google Remarketing</span> <p>This website uses the functions
                                                        of Google Analytics Remarketing. The provider of these solutions
                                                        is Google Ireland Limited (“Google”), Gordon House, Barrow
                                                        Street, Dublin 4, Ireland.</p> <p>Google Remarketing analyzes
                                                        your user patterns on our website (e.g., clicks on specific
                                                        products), to allocate a certain advertising target groups to
                                                        you and to subsequently display matching online offers to you
                                                        when you visit other online offers (remarketing or
                                                        retargeting).</p> <p>Moreover, it is possible to link the
                                                        advertising target groups generated with Google Remarketing to
                                                        device encompassing functions of Google. This makes it possible
                                                        to display interest-based customized advertising messages,
                                                        depending on your prior usage and browsing patterns on a device
                                                        (e.g., cell phone) in a manner tailored to you as well as on any
                                                        of your devices (e.g., tablet or PC).</p> <p>If you have a
                                                        Google account, you have the option to object to personalized
                                                        advertising under the following link: <a
                                                            href="https://www.google.com/settings/ads/onweb/"
                                                            target="_blank"
                                                            rel="noopener noreferrer">https://www.google.com/settings/ads/onweb/</a>.
                                                    </p> <p>The use of Google Remarketing is based on Art. 6(1)(f) GDPR.
                                                        The website operator has a legitimate interest in the marketing
                                                        of the operator’s products that is as effective as possible. If
                                                        appropriate consent has been obtained, the processing is carried
                                                        out exclusively on the basis of Art. 6(1)(a) GDPR and § 25 (1)
                                                        TTDSG, insofar the consent includes the storage of cookies or
                                                        the access to information in the user’s end device (e.g., device
                                                        fingerprinting) within the meaning of the TTDSG. This consent
                                                        can be revoked at any time.</p> <p>For further information and
                                                        the pertinent data protection regulations, please consult the
                                                        Data Privacy Policies of Google at: <a
                                                            href="https://policies.google.com/technologies/ads?hl=en"
                                                            target="_blank"
                                                            rel="noopener noreferrer">https://policies.google.com/technologies/ads?hl=en</a>.
                                                    </p>
                                                        <br/>
<span className={"text-md mt-4 pt-4"}>Formation of Target Groups with Customer Reconciliation</span>
                                                        <p>For the formation of target groups, we use, among other
                                                            things, the Google Remarketing customer reconciliation
                                                            feature. To achieve this, we transfer certain customer data
                                                            (e.g., email addresses) from our customer lists to Google.
                                                            If the respective customers are Google users and are logged
                                                            into their Google accounts, matching advertising messages
                                                            within the Google network (e.g., YouTube, Gmail or in a
                                                            search engine) are displayed for them to view.</p>
                                                        <br/>
<span className={"text-lg mt-4 pt-4"}>Google Conversion-Tracking</span> <p>This website uses Google
                                                        Conversion Tracking. The provider of this service is Google
                                                        Ireland Limited (“Google”), Gordon House, Barrow Street, Dublin
                                                        4, Ireland.</p> <p>With the assistance of Google Conversion
                                                        Tracking, we are in a position to recognize whether the user has
                                                        completed certain actions. For instance, we can analyze the how
                                                        frequently which buttons on our website have been clicked and
                                                        which products are reviewed or purchased with particular
                                                        frequency. The purpose of this information is to compile
                                                        conversion statistics. We learn how many users have clicked on
                                                        our ads and which actions they have completed. We do not receive
                                                        any information that would allow us to personally identify the
                                                        users. Google as such uses cookies or comparable recognition
                                                        technologies for identification purposes.</p> <p>We use Google
                                                        Conversion Tracking on the basis of Art. 6(1)(f) GDPR. The
                                                        operator of the website has a legitimate interest in the
                                                        analysis of the user patterns with the aim of optimizing both,
                                                        the operator’s web presentation and advertising. If appropriate
                                                        consent has been obtained, the processing is carried out
                                                        exclusively on the basis of Art. 6(1)(a) GDPR and § 25 (1)
                                                        TTDSG, insofar the consent includes the storage of cookies or
                                                        the access to information in the user’s end device (e.g., device
                                                        fingerprinting) within the meaning of the TTDSG. This consent
                                                        can be revoked at any time.</p> <p>For more information about
                                                        Google Conversion Tracking, please review Google’s data
                                                        protection policy at: <a
                                                            href="https://policies.google.com/privacy?hl=en"
                                                            target="_blank"
                                                            rel="noopener noreferrer">https://policies.google.com/privacy?hl=en</a>
                                                    </p>
                                                        <br/>
<span className={"text-lg mt-4 pt-4"}>Google DoubleClick</span> <p>This website uses features of
                                                        Google DoubleClick. The provider is Google Ireland Limited
                                                        (“Google”), Gordon House, Barrow Street, Dublin 4, Ireland,
                                                        (hereinafter “DoubleClick”).</p> <p>DoubleClick is used to show
                                                        you interest-based ads across the Google Network. Advertisements
                                                        can be tailored to the interests of the viewer using
                                                        DoubleClick. For example, our ads may appear in Google search
                                                        results or in banners associated with DoubleClick.</p> <p>To be
                                                        able to display interest adequate promotional content to users,
                                                        DoubleClick must recognize the respective visitor so that it can
                                                        allocate the websites visited, the clicks and other user pattern
                                                        information to the user. To do this, DoubleClick deploys cookies
                                                        or comparable recognition technologies (e.g., device
                                                        fingerprinting). The recorded information is consolidated into a
                                                        pseudonym user profile so that the respective user can be shown
                                                        interest adequate advertising.</p> <p>The use of Google
                                                        DoubleClick takes place in the interest of targeted advertising
                                                        measures. This constitutes a legitimate interest within the
                                                        meaning of Art. 6(1)(f) GDPR. If appropriate consent has been
                                                        obtained, the processing is carried out exclusively on the basis
                                                        of Art. 6(1)(a) GDPR and § 25 (1) TTDSG, insofar the consent
                                                        includes the storage of cookies or the access to information in
                                                        the user’s end device (e.g., device fingerprinting) within the
                                                        meaning of the TTDSG. This consent can be revoked at any
                                                        time.</p> <p>For further information on how to object to the
                                                        advertisements displayed by Google, please see the following
                                                        links: <a href="https://policies.google.com/technologies/ads"
                                                                  target="_blank"
                                                                  rel="noopener noreferrer">https://policies.google.com/technologies/ads</a> and <a
                                                            href="https://adssettings.google.com/authenticated"
                                                            target="_blank"
                                                            rel="noopener noreferrer">https://adssettings.google.com/authenticated</a>.
                                                    </p>
                                                        <br/>
<span className={"text-xl mt-4 pt-4"}>6. Plug-ins and Tools</span>
                                                        <br/>
<span className={"text-lg mt-4 pt-4"}>YouTube with expanded data protection integration</span>
                                                        <p>Our website embeds videos of the website YouTube. The website
                                                            operator is Google Ireland Limited (“Google”), Gordon House,
                                                            Barrow Street, Dublin 4, Ireland.</p> <p>We use YouTube in
                                                        the expanded data protection mode. According to YouTube, this
                                                        mode ensures that YouTube does not store any information about
                                                        visitors to this website before they watch the video.
                                                        Nevertheless, this does not necessarily mean that the sharing of
                                                        data with YouTube partners can be ruled out as a result of the
                                                        expanded data protection mode. For instance, regardless of
                                                        whether you are watching a video, YouTube will always establish
                                                        a connection with the Google DoubleClick network.</p> <p>As soon
                                                        as you start to play a YouTube video on this website, a
                                                        connection to YouTube’s servers will be established. As a
                                                        result, the YouTube server will be notified, which of our pages
                                                        you have visited. If you are logged into your YouTube account
                                                        while you visit our site, you enable YouTube to directly
                                                        allocate your browsing patterns to your personal profile. You
                                                        have the option to prevent this by logging out of your YouTube
                                                        account.</p> <p>Furthermore, after you have started to play a
                                                        video, YouTube will be able to place various cookies on your
                                                        device or comparable technologies for recognition (e.g. device
                                                        fingerprinting). In this way YouTube will be able to obtain
                                                        information about this website’s visitors. Among other things,
                                                        this information will be used to generate video statistics with
                                                        the aim of improving the user friendliness of the site and to
                                                        prevent attempts to commit fraud.</p> <p>Under certain
                                                        circumstances, additional data processing transactions may be
                                                        triggered after you have started to play a YouTube video, which
                                                        are beyond our control.</p> <p>The use of YouTube is based on
                                                        our interest in presenting our online content in an appealing
                                                        manner. Pursuant to Art. 6(1)(f) GDPR, this is a legitimate
                                                        interest. If appropriate consent has been obtained, the
                                                        processing is carried out exclusively on the basis of Art.
                                                        6(1)(a) GDPR and § 25 (1) TTDSG, insofar the consent includes
                                                        the storage of cookies or the access to information in the
                                                        user’s end device (e.g., device fingerprinting) within the
                                                        meaning of the TTDSG. This consent can be revoked at any
                                                        time.</p> <p>For more information on how YouTube handles user
                                                        data, please consult the YouTube Data Privacy Policy under: <a
                                                            href="https://policies.google.com/privacy?hl=en"
                                                            target="_blank"
                                                            rel="noopener noreferrer">https://policies.google.com/privacy?hl=en</a>.
                                                    </p>
                                                        <br/>
<span className={"text-lg mt-4 pt-4"}>Google Web Fonts</span> <p>To ensure that fonts used on this
                                                        website are uniform, this website uses so-called Web Fonts
                                                        provided by Google. When you access a page on our website, your
                                                        browser will load the required web fonts into your browser cache
                                                        to correctly display text and fonts.</p> <p>To do this, the
                                                        browser you use will have to establish a connection with
                                                        Google’s servers. As a result, Google will learn that your IP
                                                        address was used to access this website. The use of Google Web
                                                        Fonts is based on Art. 6(1)(f) GDPR. The website operator has a
                                                        legitimate interest in a uniform presentation of the font on the
                                                        operator’s website. If appropriate consent has been obtained,
                                                        the processing is carried out exclusively on the basis of Art.
                                                        6(1)(a) GDPR and § 25 (1) TTDSG, insofar the consent includes
                                                        the storage of cookies or the access to information in the
                                                        user’s end device (e.g., device fingerprinting) within the
                                                        meaning of the TTDSG. This consent can be revoked at any
                                                        time.</p> <p>If your browser should not support Web Fonts, a
                                                        standard font installed on your computer will be used.</p>
                                                        <p>For more information on Google Web Fonts, please follow this
                                                            link: <a href="https://developers.google.com/fonts/faq"
                                                                     target="_blank"
                                                                     rel="noopener noreferrer">https://developers.google.com/fonts/faq</a> and
                                                            consult Google’s Data Privacy Declaration under: <a
                                                                href="https://policies.google.com/privacy?hl=en"
                                                                target="_blank"
                                                                rel="noopener noreferrer">https://policies.google.com/privacy?hl=en</a>.
                                                        </p>
                                                        <br/>
<span className={"text-lg mt-4 pt-4"}>Font Awesome</span> <p>This page uses Font Awesome for the
                                                        uniform representation of fonts and symbols. Provider is
                                                        Fonticons, Inc. 6 Porter Road Apartment 3R, Cambridge,
                                                        Massachusetts, USA.</p> <p>When you call up a page, your browser
                                                        loads the required fonts into its browser cache to display
                                                        texts, fonts, and symbols correctly. For this purpose, the
                                                        browser you use must connect to the servers of Font Awesome.
                                                        This allows Font Awesome to know that your IP address has been
                                                        used to access this website. The use of Font Awesome is based on
                                                        Art. 6(1)(f) GDPR. We have a legitimate interest in the uniform
                                                        presentation of the typeface on our website. If appropriate
                                                        consent has been obtained, the processing is carried out
                                                        exclusively on the basis of Art. 6(1)(a) GDPR and § 25 (1)
                                                        TTDSG, insofar the consent includes the storage of cookies or
                                                        the access to information in the user’s end device (e.g., device
                                                        fingerprinting) within the meaning of the TTDSG. This consent
                                                        can be revoked at any time.</p> <p>If your browser does not
                                                        support Font Awesome, a standard font from your computer will be
                                                        used.</p> <p>Further information about Font Awesome can be found
                                                        in the Font Awesome privacy policy at: <a
                                                            href="https://fontawesome.com/privacy" target="_blank"
                                                            rel="noopener noreferrer">https://fontawesome.com/privacy</a>.
                                                    </p>
                                                        <br/>
<span className={"text-lg mt-4 pt-4"}>Google reCAPTCHA</span> <p>We use “Google reCAPTCHA”
                                                        (hereinafter referred to as “reCAPTCHA”) on this website. The
                                                        provider is Google Ireland Limited (“Google”), Gordon House,
                                                        Barrow Street, Dublin 4, Ireland.</p> <p>The purpose of
                                                        reCAPTCHA is to determine whether data entered on this website
                                                        (e.g., information entered into a contact form) is being
                                                        provided by a human user or by an automated program. To
                                                        determine this, reCAPTCHA analyzes the behavior of the website
                                                        visitors based on a variety of parameters. This analysis is
                                                        triggered automatically as soon as the website visitor enters
                                                        the site. For this analysis, reCAPTCHA evaluates a variety of
                                                        data (e.g., IP address, time the website visitor spent on the
                                                        site or cursor movements initiated by the user). The data
                                                        tracked during such analyses are forwarded to Google.</p>
                                                        <p>reCAPTCHA analyses run entirely in the background. Website
                                                            visitors are not alerted that an analysis is underway.</p>
                                                        <p>Data are stored and analyzed on the basis of Art. 6(1)(f)
                                                            GDPR. The website operator has a legitimate interest in the
                                                            protection of the operator’s websites against abusive
                                                            automated spying and against SPAM. If appropriate consent
                                                            has been obtained, the processing is carried out exclusively
                                                            on the basis of Art. 6(1)(a) GDPR and § 25 (1) TTDSG,
                                                            insofar the consent includes the storage of cookies or the
                                                            access to information in the user’s end device (e.g., device
                                                            fingerprinting) within the meaning of the TTDSG. This
                                                            consent can be revoked at any time.</p> <p>For more
                                                        information about Google reCAPTCHA please refer to the Google
                                                        Data Privacy Declaration and Terms Of Use under the following
                                                        links: <a href="https://policies.google.com/privacy?hl=en"
                                                                  target="_blank"
                                                                  rel="noopener noreferrer">https://policies.google.com/privacy?hl=en</a> and <a
                                                            href="https://policies.google.com/terms?hl=en"
                                                            target="_blank"
                                                            rel="noopener noreferrer">https://policies.google.com/terms?hl=en</a>.
                                                    </p>
                                                        <br/>
<span className={"text-xl mt-4 pt-4"}>7. Online marketing and partner programs</span>
                                                        <br/>
<span className={"text-lg mt-4 pt-4"}>Affiliate Programs on this website</span> <p>The website
                                                        operator participates in affiliate partner programs. If you
                                                        click on a promotional ad on our website that participates in
                                                        the partner program and subsequently engage in a transaction
                                                        (e.g., a purchase), we will receive a commission from our
                                                        affiliated partners. Hence, it is necessary that our affiliate
                                                        partners can identify you and trace the fact that you were
                                                        referred to the respective product through the promotional ad
                                                        placed with us and that you have executed the predefined
                                                        transaction. For this purpose, our affiliate partners use
                                                        cookies or comparable recognition technology (e.g., device
                                                        fingerprinting).</p> <p>Data is stored and analyzed on the basis
                                                        of Art. 6(1)(f) GDPR. The website operator has a legitimate
                                                        interest in the correct computation of its affiliate
                                                        compensation. If appropriate consent has been obtained, the
                                                        processing is carried out exclusively on the basis of Art.
                                                        6(1)(a) GDPR and § 25 (1) TTDSG, insofar the consent includes
                                                        the storage of cookies or the access to information in the
                                                        user’s end device (e.g., device fingerprinting) within the
                                                        meaning of the TTDSG. This consent can be revoked at any
                                                        time.</p>
                                                        <p>We participate in the following affiliate programs:</p>
                                                        <br/>
<span className={"text-md mt-4 pt-4"}>Amazon partner program</span> <p>The provider is Amazon Europe
                                                        Core S.à.r.l. For details, please consult Amazon’s Data Privacy
                                                        Declaration at: <a
                                                            href="https://www.amazon.de/gp/help/customer/display.html?nodeId=201909010"
                                                            target="_blank"
                                                            rel="noopener">https://www.amazon.de/gp/help/customer/display.html?nodeId=201909010</a>.
                                                    </p>
                                                        <br/>
<span className={"text-md mt-4 pt-4"}>AWIN</span> <p>The operator of the affiliate network is AWIN
                                                        AG, Eichhornstraße 3, 10785 Berlin (hereinafter referred to as
                                                        “AWIN”). We and AWIN and, if applicable, the advertiser, are
                                                        jointly responsible for the data processing in connection with
                                                        the partner program. Our joint responsibilities are defined and
                                                        documented in an agreement pertaining to our joint processing.
                                                        According to this agreement, you as the data subject can contact
                                                        either responsible party with your request. The responsible
                                                        party you contact first shall respond to your inquiry. Every
                                                        responsible party shall keep their own data protection
                                                        information pursuant to Art. 13, 14 and 26 GDPR handy and shall
                                                        take the required measures to protect personal data and to
                                                        comply with all other GDPR regulations at the respective
                                                        company. The agreement on joint processing can be found in the
                                                        AWIN GTC under the following link: <a
                                                            href="https://s3.amazonaws.com/docs.awin.com/Legal/Publisher+Terms/2020/DE+Publisher+Terms+GDPR+Annex.pdf"
                                                            target="_blank"
                                                            rel="noopener noreferrer">https://s3.amazonaws.com/docs.awin.com/Legal/Publisher+Terms/2020/DE+Publisher+Terms+GDPR+Annex.pdf</a>.
                                                    </p></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                        <button
                                            type="button"
                                            className="flex items-center justify-center h-12 lg:h-16 w-32 lg:w-40 bg-brand-yellow border-2 border-brand-yellow hover:text-brand-light-gray hover:bg-transparent transition-all ease-in-out duration-300"
                                            onClick={() => setOpen(false)}
                                        >
                                            Ok, thanks
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    )
}
