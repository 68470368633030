import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/img/logo.svg";
import heroBg from "../assets/video/gravity-sequence-c.mp4";

const Hero = () => {
  const vid = Video();

  return (
    <div className="relative h-screen bg-black">
      <div className="relative z-10 max-w-[1370px] mx-auto h-full flex flex-col justify-between md:text-lg lg:text-xl px-4">
        {/* heading */}

        <div className="flex items-center justify-between font-gotham text-white space-x-4 pt-5 lg:pt-11">
          <Link
            className="hover:text-brand-yellow duration-300 transition ease-in-out p-3"
            to="#"
          >
           {/*  work*/}
          </Link>
          <Link to="#">
            <img src={logo} alt="" />
          </Link>
          <Link
            className="hover:text-brand-yellow duration-300 transition ease-in-out p-3"
            to="#"
          >
              {/*  work*/}
          </Link>
        </div>

        <div className="flex flex-col text-white font-gotham space-y-16 py-16 lg:pb-40">
          <Link
            className="hover:text-brand-yellow hover:translate-x-8 duration-500 transition ease-in-out py-2"
            to="/"
            onClick={() => window.location.replace("/#projects")}
          >
            Projects
          </Link>
          <Link
            className="hover:text-brand-yellow hover:translate-x-8 duration-500 transition ease-in-out py-2"
            to="/"
            onClick={() => window.location.replace("/#services")}
          >
            Services
          </Link>
          <Link
            className="hover:text-brand-yellow hover:translate-x-8 duration-500 transition ease-in-out py-2"
            to="/"
            onClick={() => window.location.replace("/#skills")}
          >
            Skills
          </Link>
          <Link
            className="hover:text-brand-yellow hover:translate-x-8 duration-500 transition ease-in-out py-2"
            to="/"
            onClick={() => window.location.replace("/#contact")}
          >
            Contact
          </Link>
        </div>
      </div>
      {vid}
    </div>
  );
};

export function Video() {
  const refVideo = useRef(null);

  useEffect(() => {
    if (!refVideo.current) {
      return;
    }
    //open bug since 2017 that you cannot set muted in video element https://github.com/facebook/react/issues/10389
    refVideo.current.defaultMuted = true;
    refVideo.current.muted = true;
  });

  return (
    <video
      className="absolute z-0 top-0 left-0 w-full h-full object-cover object-center opacity-90"
      src={heroBg}
      ref={refVideo}
      autoPlay
      loop
      playsInline //FIX iOS black screen
    />
  );
}

export default Hero;
