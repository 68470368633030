import React from "react";
import { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import leftArrow from "../assets/img/iconLeft.svg";
import rightArrow from "../assets/img/iconRight.svg";
import whatsapi from "../assets/img/whatsapi.png";
import whatsappbutton from "../assets/img/whatsappbutton.png";
import whatsappbis from "../assets/img/whatsapp-bis.png";
import inventoryalerts from "../assets/img/Simple_inventory_Alerts_logo.png";
import bgImg from "../assets/img/servicesBg.svg";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {Link} from "react-router-dom";

const apps = [
  {
    id: "0",
    iconOne: `${whatsapi}`,
    headingOne: "WaAPI",
    contentOne:
      "Connect your personal WhatsApp phone number with our service and be able to control WhatsApp automatically via HTTP requests.",
    linkOne: "https://waapi.app",
    linkOneText: "to the App",
    iconTwo: `${whatsappbutton}`,
    headingTwo: "WhatsApp Button",
    contentTwo:
      "Show a WhatsApp Chat Button in your shop so that customers can directly send you a message.",
    linkTwo: "https://apps.shopify.com/whatsapp-button-1",
    linkTwoText: "to Shopify Appstore",
  },
  {
    id: "1",
    iconOne: `${whatsappbis}`,
    headingOne: "Whatsapp - Back in Stock",
    contentOne:
      "Notify potential customers when a subscribed product is back in stock after it was sold out.",
    linkOne: "https://apps.shopify.com/simple-stock-alerts",
    linkOneText: "to Shopify Appstore",
    iconTwo: `${inventoryalerts}`,
    headingTwo: "Simple Inventory Alerts",
    contentTwo:
      "Get notifications when a product is out of stock in your inventory with individual thresholds.",
    linkTwo: "https://apps.shopify.com/simple-inventory-alerts",
    linkTwoText: "to Shopify Appstore",
  }

];

const Apps = () => {
  const navigationNextRef = React.useRef(null);
  const navigationPrevRef = React.useRef(null);

  return (
    <div
      id="apps"
      className="relative bg-black overflow-hidden pt-14 pb-10 md:pb-20 lg:pb-28"
    >
      <div className="relative z-10">
        <h1 className="font-black font-gotham text-4xl md:text-5xl lg:text-6xl text-brand-yellow px-4 pb-16 lg:pl-32 xl:pl-52">
          Apps
        </h1>

        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          spaceBetween={70}
          slidesPerView={1.1}
          breakpoints={{
            640: {
              slidesPerView: 1.5,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 2.3,
            },
          }}
          loop
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          navigation={{
            nextEl: navigationNextRef.current,
            prevEl: navigationPrevRef.current,
          }}
          onBeforeInit={(swiper) => {
            swiper.params.navigation.nextEl = navigationNextRef.current;
            swiper.params.navigation.prevEl = navigationPrevRef.current;
          }}
          pagination={{
            el: ".custom-pagination",
            clickable: true,
          }}
          className="max-w-7xl mx-auto !overflow-visible"
        >
          {apps.map((slide) => (
            <SwiperSlide key={slide.id}>
              <div>
                <div className="flex items-start bg-white/5 rounded-3xl h-80 space-x-6 p-4 xl:p-8 pb-24">
                  <img
                    className="w-16 h-16 lg:w-14 xl:w-auto"
                    src={slide.iconOne}
                    alt=""
                  />
                  <div className="font-gotham text-brand-light-gray pt-4">
                    <h2 className="font-bold text-2xl lg:text-3xl xl:text-4xl">
                      {slide.headingOne}
                    </h2>
                    <p className="xl:text-xl pt-4 xl:pt-6">
                      {slide.contentOne}
                    </p>
                    <Link className="group flex items-center space-x-8 mt-4" to={slide.linkOne} target={"_blank"}>
                      <img
                          className="group-hover:translate-x-4 transition duration-500 ease-in-out"
                          src={rightArrow}
                          alt=""
                      />
                      <span className="font-avenir text-lg lg:text-xl text-brand-yellow underline decoration-brand-yellow hover:decoration-transparent duration-500 transition ease-in-out">
                    {slide.linkOneText}
                  </span>
                    </Link>
                  </div>
                </div>
                <div className="flex items-start bg-white/5 rounded-3xl h-72 space-x-6 p-4 xl:p-8 pb-24 mt-12">
                  <img
                    className="w-16 h-16 lg:w-14 xl:w-auto"
                    src={slide.iconTwo}
                    alt=""
                  />
                  <div className="font-gotham text-brand-light-gray pt-4">
                    <h2 className="font-bold text-2xl lg:text-3xl xl:text-4xl">
                      {slide.headingTwo}
                    </h2>
                    <p className="xl:text-xl pt-4 xl:pt-6">
                      {slide.contentTwo}
                    </p>
                    <Link className="group flex items-center space-x-8 mt-4" to={slide.linkTwo} target={"_blank"}>
                      <img
                          className="group-hover:translate-x-4 transition duration-500 ease-in-out"
                          src={rightArrow}
                          alt=""
                      />
                      <span className="font-avenir text-lg lg:text-xl text-brand-yellow underline decoration-brand-yellow hover:decoration-transparent duration-500 transition ease-in-out">
                    {slide.linkTwoText}
                  </span>
                    </Link>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
          <div className="relative z-50 max-w-5xl mx-auto flex items-center justify-end pt-6 pr-4">
            <div className="cursor-pointer p-2" ref={navigationPrevRef}>
              <img src={leftArrow} alt="" />
            </div>
            <div className="custom-pagination !w-auto space-x-2">
              {apps.length}
            </div>
            <div className="flex items-center font-gotham font-black text-brand-yellow"></div>
            <div className="cursor-pointer p-2" ref={navigationNextRef}>
              <img src={rightArrow} alt="" />
            </div>
          </div>
        </Swiper>
      </div>

      {/* bg shape */}
      <img
        className="absolute z-0 inset-0 w-full h-full object-cover mix-blend-luminosity opacity-70"
        src={bgImg}
        alt=""
      />
    </div>
  );
};

export default Apps;
