import React from "react";
import {Link} from "react-router-dom";
import github from "../assets/img/github.svg";
import discord from "../assets/img/discord.svg";
import twitter from "../assets/img/twitter.svg";
import linkedIn from "../assets/img/linkedIn.svg";
import plusPlus from "../assets/img/plusPlus.svg";

export class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            phone: "",
            message: "",
            submitted: false,
            buttonText: "send"
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();


        // handle input check
        if (this.state.name === "") {
            console.log('name empty');
            return;
        }

        if (this.state.message === "") {
            console.log('name empty');
            return;
        }

        if (this.state.email === "") {
            console.log('name empty');
            return;
        }

        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(this.state)
        };

        fetch('https://api.gty.ag/api/v1/email/contact', requestOptions)
            .then(response => console.log(response.json()));

        this.setState({buttonText: "Thank you", submitted: true});
    }

    render() {

        return (
            <div id="contact" className="relative z-50 bg-black">
                <div className="bg-contact bg-no-repeat bg-right-bottom py-20 lg:pb-40 px-4">
                    <div className="relative z-10 px-4 lg:pl-32 xl:pl-52 lg:pr-28">
                        <h1 className="font-black font-gotham text-4xl md:text-5xl lg:text-6xl text-brand-yellow pb-6 lg:pb-10">
                            Contact us
                        </h1>

                        <div className="flex flex-col md:flex-row items-start justify-between md:space-x-14">
                            <div className="w-full">
                                <p className="font-gotham font-light text-xl lg:text-2xl text-brand-light-gray">
                                    Any questions about your project?
                                </p>
                                <form className="md:max-w-lg w-full pt-10 md:pt-20" onSubmit={this.handleSubmit}>
                                    <div className="relative mb-14">
                                        <input
                                            className="peer w-full caret-white border-b border-brand-yellow text-xl font-medium font-avenir text-brand-light-gray bg-transparent placeholder-transparent focus:outline-none py-2"
                                            type="text"
                                            name="name"
                                            id="name"
                                            placeholder=" "
                                            value={this.state.name}
                                            onChange={this.handleInputChange}
                                        />
                                        <label
                                            className="absolute left-0 origin-[0] text-xl -translate-y-6 top-2 scale-75 font-avenir font-medium text-brand-yellow peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 transition-all duration-300"
                                            htmlFor="name"
                                        >
                                            Name
                                        </label>
                                    </div>

                                    <div className="relative mb-14">
                                        <input
                                            className="peer w-full caret-white border-b border-brand-yellow text-xl font-medium font-avenir text-brand-light-gray bg-transparent placeholder-transparent focus:outline-none py-2"
                                            type="email"
                                            name="email"
                                            id="email"
                                            placeholder=" "
                                            value={this.state.email}
                                            onChange={this.handleInputChange}
                                        />
                                        <label
                                            className="absolute left-0 origin-[0] text-xl -translate-y-6 top-2 scale-75 font-avenir font-medium text-brand-yellow peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 transition-all duration-300"
                                            htmlFor="email"
                                        >
                                            E-mail
                                        </label>
                                    </div>

                                    <div className="relative mb-20">
                                        <input
                                            className="peer w-full caret-white border-b border-brand-yellow text-xl font-medium font-avenir text-brand-light-gray bg-transparent placeholder-transparent focus:outline-none py-2"
                                            type="text"
                                            name="phone"
                                            id="phone"
                                            placeholder=" "
                                            value={this.state.phone}
                                            onChange={this.handleInputChange}
                                        />
                                        <label
                                            className="absolute left-0 origin-[0] text-xl -translate-y-6 top-2 scale-75 font-avenir font-medium text-brand-yellow peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 transition-all duration-300"
                                            htmlFor="phone"
                                        >
                                            Phone
                                        </label>
                                    </div>

                                    <div className="relative mb-14">
                  <textarea
                      className="peer w-full caret-white border-b border-brand-yellow text-xl font-medium font-avenir text-brand-light-gray bg-transparent placeholder-transparent focus:outline-none py-2"
                      name="message"
                      id="message"
                      cols="30"
                      rows=""
                      placeholder=" "
                      value={this.state.message}
                      onChange={this.handleInputChange}
                  ></textarea>
                                        <label
                                            className="absolute left-0 origin-[0] text-xl -translate-y-6 top-2 scale-75 font-avenir font-medium text-brand-yellow peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 transition-all duration-300"
                                            htmlFor="message"
                                        >
                                            Message
                                        </label>
                                    </div>

                                    <div>
                                        <button
                                            className={this.state.submitted ? "flex items-center justify-center h-12 lg:h-16 w-32 lg:w-40 bg-brand-yellow border-2 border-brand-yellow transition-all ease-in-out duration-300" : "flex items-center justify-center h-12 lg:h-16 w-32 lg:w-40 bg-brand-yellow border-2 border-brand-yellow hover:text-brand-light-gray hover:bg-transparent transition-all ease-in-out duration-300"}
                                            type="submit"
                                            disabled={this.state.submitted}
                                        >
                    <span className="block font-avenir font-bold text-lg md:text-xl uppercase">
                      {this.state.buttonText}
                    </span>
                                        </button>
                                    </div>
                                </form>
                            </div>

                            <div
                                className="order-first md:order-last relative font-gotham md:max-w-[250px] pb-20 md:pb-0 pt-5 md:mt-10">
                                <h5 className="font-medium text-2xl text-brand-yellow pb-6">
                                    Contact Info
                                </h5>
                                <p className="text-brand-light-gray text-xl">
                                    Gravity Technologies AG Chamerstrasse 172 CH – 6300 Zug
                                </p>
                                <br/>
                                <p className="text-brand-light-gray text-xl">
                                    Mail: info@gty.ag
                                </p>
                                <p className="text-brand-light-gray text-xl pb-6">
                                    Monday – Friday
                                </p>
                                <h5 className="font-medium text-2xl text-brand-yellow pb-6">
                                    Follow Us
                                </h5>

                                {/* social links */}
                                <div className="flex items-center space-x-4">
                                    <Link
                                        to="https://github.com/gravitytech"
                                        target={"_blank"}
                                        className="hover:-translate-y-1.5 transition ease-in-out duration-300"
                                    >
                                        <img src={github} alt=""/>
                                    </Link>
                                </div>

                                {/* plus plus */}
                                <img
                                    className="absolute -top-8 right-0 md:-left-28"
                                    src={plusPlus}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}


