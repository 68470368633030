import React, {useEffect, useRef, useState} from "react";
import useOnScreen from "../hooks/onscreen";

const Customers = () => {
    const [customerCount, setCustomerCount] = useState(0);
    const [animationTimer, setAnimationTimer] = useState(null);
    const ref = useRef(null);
    const isVisible = useOnScreen(ref);

    useEffect(() => {
        const listener = () => {
            if (isVisible) {
                startCountAnimation();
            }
        };

        window.addEventListener('scroll', listener);

        return () => {
            window.removeEventListener('scroll', listener);
        };
    });

    const startCountAnimation = () => {
        if (animationTimer != null) return;

        const startDate = 1679414201355;
        const startUsers = 1215;
        const weeklyNewUsers = 200;

        const weeksDiff = (Date.now() - startDate) / (7 * 24 * 60 * 60 * 1000);
        const calculatedCurrentUsers = Math.floor(startUsers + (weeksDiff * weeklyNewUsers));

        const timerStartTime = Date.now();
        const speed = 0.000065 + (0.00002 * calculatedCurrentUsers / 1000);

        const timer = setInterval(() => {
            const msDiff = Date.now() - timerStartTime;
            const usersToShow = Math.floor(speed * Math.pow(msDiff, 2));

            if (usersToShow >= calculatedCurrentUsers) {
                setCustomerCount(calculatedCurrentUsers);
                setAnimationTimer(null);
                clearInterval(timer);
            } else {
                if (usersToShow > customerCount) {
                    setCustomerCount(usersToShow);
                }
            }

        }, 50);

        setAnimationTimer(timer);
    };

    return (
        <div id="customers" className="relative bg-black pt-12 lg:pt-24 pb-20 lg:pb-40">
            <div className="relative z-20 px-4 lg:pl-32 xl:pl-52 lg:pr-28">
                <h1 className="font-black font-gotham text-4xl md:text-5xl lg:text-6xl text-brand-yellow pb-8 lg:pb-14">
                    Customers
                </h1>
                <div ref={ref} className="flex flex-col sm:flex-row items-start sm:items-center gap-8 sm:gap-16">
                    <div className="pt-3 sm:p-6 flex sm:items-center min-w-[11rem]">
                        <div className="font-black font-gotham text-4xl md:text-5xl lg:text-6xl text-brand-yellow font-bold sm:text-center w-full">
                            {customerCount}
                        </div>
                    </div>
                    <div className="max-w-3xl">
                        <p className="max-w-4xl font-avenir text-brand-light-gray md:text-lg lg:text-xl">
                            Overall, we have worked and are responsible for more than {customerCount} different
                            customers. Our customers
                            rely on our experience, professionalism and flexibility.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Customers;
