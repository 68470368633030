import { BrowserRouter as Router } from "react-router-dom";
import Hero from "./components/Hero";
import Facts from "./components/Facts";
import Project from "./components/Project";
import Services from "./components/Services";
import Skills from "./components/Skills";
import Footer from "./components/Footer";
import Apps from "./components/Apps";
import {Contact} from "./components/Contact"

import heatmap from "./assets/img/lineDots.svg";
import Customers from "./components/Customers";

function App() {
  return (
    <Router className="bg-black text-white">
      <Hero />
      <main className="relative">
        <Facts />
        <Customers />
        <Apps />
        <Project />
        <Services />
        <Skills />
        <Contact />

        {/* heatmap */}
        <img
          className="hidden lg:block absolute z-50 top-5 left-1 lg:left-10 xl:left-20 h-[90%]"
          src={heatmap}
          alt=""
        />
      </main>
      <Footer />
    </Router>
  );
}

export default App;
