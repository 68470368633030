import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import slide1 from "../assets/img/slide1.png";
import slide2 from "../assets/img/slide2.png";
import slide3 from "../assets/img/slide3.png";
import slide4 from "../assets/img/slide4.webp";
import leftArrow from "../assets/img/iconLeft.svg";
import rightArrow from "../assets/img/iconRight.svg";
import plusPlus from "../assets/img/plusPlus.svg";

import "swiper/css";
import "swiper/css/navigation";

const slider = [
  {
    id: "0",
    img: `${slide2}`,
    heading: "Porsche Apps",
    content:
      "Porsche is one of the most respected car manufacturers in the world and is expanding its digital division more and more every day to stay that way. We supported Porsche Digital in the areas of app development, publishing and UI/UX.",
    link: "https://www.porsche.digital/",
  },
  {
    id: "1",
    img: `${slide1}`,
    heading: "About You",
    content:
      "About You is one of the largest fashion retailers in Europe and serves thousands of customers every day with over 47 stores. We supported About You in the areas of performance optimization. Cloud API solutions were used, as well as Headless and AWS.",
    link: "https://www.aboutyou.de/",
  },
  {
    id: "2",
    img: `${slide3}`,
    heading: "paysafecard",
    content:
      "Paysafecard is an electronic means of payment based on the prepaid principle for payments on the Internet. For the customer paysafecard we wrote the documentation and sample usage of the official interface for other developers within the paysafecard eco-system.",
    link: "https://www.paysafecard.com/fileadmin/api/index.html",
  }
];

const Project = () => {
  const [activeIndex, setActiveIndex] = useState(1);
  const navigationNextRef = React.useRef(null);
  const navigationPrevRef = React.useRef(null);

  return (
    <div
      id="projects"
      className="relative bg-black pb-10 md:pb-20 lg:pb-36 overflow-hidden pt-10"
    >
      <h1 className="font-black font-gotham text-4xl md:text-5xl lg:text-6xl text-brand-yellow px-4 pb-11 lg:pl-32 xl:pl-52">
        Projects
      </h1>

      <Swiper
        modules={[Navigation, Autoplay]}
        spaceBetween={30}
        slidesPerView={1}
        centeredSlides={true}
        loop
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        navigation={{
          nextEl: navigationNextRef.current,
          prevEl: navigationPrevRef.current,
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.nextEl = navigationNextRef.current;
          swiper.params.navigation.prevEl = navigationPrevRef.current;
        }}
        onSlideChange={(index) => setActiveIndex(index.realIndex + 1)}
        className="project max-w-5xl mx-auto !overflow-visible"
      >
        {slider.map((slide) => (
          <SwiperSlide key={slide.id}>
            <div className="md:flex md:h-[450px] lg:h-[600px] px-5">
              <div className="w-full md:w-1/2 h-80 sm:h-96 md:h-auto">
                <img
                  className="w-full h-full object-cover object-center"
                  src={slide.img}
                  alt=""
                />
              </div>
              <div className="w-full md:w-1/2 bg-gradient-to-br from-white/10 to-white/5 py-6 lg:py-16 px-6 lg:px-12">
                <h2 className="font-gotham fonligh text-4xl md:text-5xl lg:text-6xl text-brand-light-gray pb-6 lg:pb-14">
                  {slide.heading}
                </h2>
                <p className="font-avenir text-xl text-brand-light-gray pb-10 md:pb-20 lg:pb-32">
                  {slide.content}
                </p>
                <Link className="group flex items-center space-x-8" to={slide.link} target={"_blank"}>
                  <img
                    className="group-hover:translate-x-4 transition duration-500 ease-in-out"
                    src={rightArrow}
                    alt=""
                  />
                  <span className="font-avenir text-lg lg:text-xl text-brand-yellow underline decoration-brand-yellow hover:decoration-transparent duration-500 transition ease-in-out">
                    Project
                  </span>
                </Link>
              </div>
            </div>
          </SwiperSlide>
        ))}

        <div className="relative z-50 flex items-center justify-end space-x-6 pt-4 pr-4">
          <div className="cursor-pointer p-2" ref={navigationPrevRef}>
            <img src={leftArrow} alt="" />
          </div>
          <div className="flex items-center font-avenir font-black text-brand-yellow">
            <span className="text-4xl pb-1">{activeIndex}</span>
            <span>/</span>
            <span className="text-2xl pt-1">{slider.length}</span>
          </div>
          <div className="cursor-pointer p-2" ref={navigationNextRef}>
            <img src={rightArrow} alt="" />
          </div>
        </div>
      </Swiper>

      {/* plus plus */}
      <img
        className="absolute z-50 left-1/2 -translate-x-1/2 top-16 lg:top-24"
        src={plusPlus}
        alt=""
      />
    </div>
  );
};

export default Project;
